import React, { useContext, useEffect, useState } from "react";
import { OrderInventoryTableColumns } from "../../utils/tables/columns";
import { Card, Grid, Row, Select, Table, Tooltip } from "antd";
import { OrderReport } from "../../interfaces/interfaces";
import DataContext from "../../context/DataContext";
import { ColumnComponent } from "../../components/ui";
import { ExportOutlined } from "@ant-design/icons";
import type { ColumnsType, TableProps } from "antd/es/table";
import dayjs from "dayjs";
import exportFromJSON from "export-from-json";

interface Props {
  [x: string]: number;
}

const statusOptions = [
  {
    text: "Received",
    value: "Received",
  },
  {
    text: "Doped",
    value: "Doped",
  },
  {
    text: "Shipped",
    value: "Shipped",
  },
];

export const OrderInventory = () => {
  const [data, setData] = useState<OrderReport[]>([]);
  const [footer, setFooter] = useState<Props>({});
  const [filters, setFilters] = useState({
    status: null,
    drillingCompanies: null,
    rigNumbers: null,
  });
  const [columns, setColumns] = useState<ColumnsType<OrderReport>>([]);
  const {
    isLoading,
    drillingCompanies,
    rigNumbers,
    fetchDataReport,
    inventoryReportData,
  } = useContext(DataContext);
  const [isLoadinTable, setIsLoadinTable] = useState(false);
  const [exportData, setExportData] = useState<OrderReport[]>([]);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  const size = xs ? "small" : "middle";
  useEffect(() => {
    fetchDataReport({});
  }, []);

  useEffect(() => {
    setIsLoadinTable(true);
    if (inventoryReportData) {
      setData(inventoryReportData.dataOrders);
      setExportData(inventoryReportData.dataOrders);
      setFooter(inventoryReportData.dataTotal);
      if (drillingCompanies && rigNumbers) {
        getColumns(inventoryReportData.dataTotal);
      } else {
        setColumns(OrderInventoryTableColumns);
        setIsLoadinTable(false);
      }
    }
  }, [inventoryReportData]);

  const onChange: TableProps<OrderReport>["onChange"] = (
    _,
    filters,
    sorter,
    extra
  ) => {
    setExportData(extra.currentDataSource);
  };

  const handleChange = (value: string[], key: string) => {
    let query = value.length > 0 ? value : null;
    let newFilters = { ...filters, [key]: query };
    fetchDataReport(newFilters);
    setFilters(newFilters);
  };

  const getColumns = (dataTotal: { [x: string]: number }) => {
    if (inventoryReportData) {
      let newColumns: any[] = Object.keys(dataTotal).map((key) => {
        return {
          title: key,
          dataIndex: key,
          width: "10%",
          className: "cell",
        };
      });
      let columns: ColumnsType<OrderReport> = [
        {
          title: "Status",
          dataIndex: "Status",
          key: "0",
          fixed: "left",
          width: "10%",
          className: "cell",
          sorter: {
            compare: (a: OrderReport, b: OrderReport) => {
              if (a.Status && b.Status) return a.Status.localeCompare(b.Status);
            },
            multiple: 1,
          },
        },
        {
          title: "Received",
          dataIndex: "DateReceived",
          key: "DateReceived",
          render: (params) => (
            <>{params ? dayjs(params).format("MM/DD/YYYY") : ""}</>
          ),
          fixed: "left",
          width: "10%",
          className: "cell",
          sorter: {
            compare: (a: OrderReport, b: OrderReport) => {
              if (a.DateReceived && b.DateReceived)
                return dayjs(a.DateReceived).isBefore(dayjs(b.DateReceived));
            },
            multiple: 2,
          },
        },
        {
          title: "Drilling Co",
          dataIndex: "DrillingCompany",
          key: "DrillingCompany",
          fixed: "left",
          width: 120,
          filterSearch: true,
          filterMode: "tree",
          className: "cell",
          sorter: {
            compare: (a: OrderReport, b: OrderReport) => {
              if (a.DrillingCompany && b.DrillingCompany)
                return a.DrillingCompany.localeCompare(b.DrillingCompany);
            },
            multiple: 2,
          },
        },
        {
          title: "Rig No",
          dataIndex: "RigNumber",
          key: "RigNumber",
          fixed: "left",
          width: "10%",
          filterSearch: true,
          filterMode: "tree",
          className: "cell",
          sorter: {
            compare: (a: OrderReport, b: OrderReport) => {
              if (a.RigNumber && b.RigNumber)
                return a.RigNumber.localeCompare(b.RigNumber);
            },
            multiple: 3,
          },
        },
        {
          title: "Order ID",
          dataIndex: "OrderId",
          key: "OrderId",
          fixed: "left",
          width: "10%",
          className: "cell",
          sorter: {
            compare: (a: OrderReport, b: OrderReport) => {
              if (a.OrderId && b.OrderId) return a.OrderId - b.OrderId;
            },
            multiple: 4,
          },
        },
        {
          title: "Estimate No.",
          dataIndex: "EstimateNo",
          key: "EstimateNo",
          fixed: "left",
          width: "10%",
          className: "cell",
          sorter: {
            compare: (a: OrderReport, b: OrderReport) => {
              if (a.EstimateNo && b.EstimateNo)
                return a.EstimateNo - b.EstimateNo;
            },
            multiple: 5,
          },
        },
        {
          title: "Location",
          dataIndex: "Location",
          key: "Location",
          fixed: "left",
          width: "10%",
          className: "cell",
          sorter: {
            compare: (a: OrderReport, b: OrderReport) => {
              if (a.Location && b.Location)
                return a.Location.localeCompare(b.Location);
            },
            multiple: 6,
          },
        },
        ...newColumns,
        {
          title: "Grand Total",
          dataIndex: "total",
          fixed: "right",
          className: "cell",
          width: "10%",
        },
      ];
      setIsLoadinTable(false);
      setColumns(columns);
    }
  };

  const button = {
    title: "Export",
    icon: <ExportOutlined />,
    action: () => {
      let fileName = "report " + dayjs(new Date());
      console.log(exportData);
      const dataToExport = exportData.map((line) => {
        return {
          ...line,
          DateReceived: dayjs(line.DateReceived).format("MM/DD/YYYY"),
        };
      });
      let data = dataToExport;
      exportFromJSON({ data, fileName, exportType: "csv" });
    },
  };

  const blankGrid: React.CSSProperties = {
    width: `90%`,
    padding: 15,
    textAlign: "center",
  };
  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <Card bordered={false} size="small" title={"Report"}>
            <Card.Grid style={blankGrid} hoverable={false}>
              <Row>
                <ColumnComponent md={8} lg={8} xl={8}>
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    placeholder="Filter by Status"
                    onChange={(val) => handleChange(val, "status")}
                    options={statusOptions}
                  />
                </ColumnComponent>
                <ColumnComponent md={8} lg={8} xl={8}>
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    placeholder="Filter by Drilling Company"
                    onChange={(val) => handleChange(val, "drillingCompanies")}
                    options={drillingCompanies}
                  />
                </ColumnComponent>
                <ColumnComponent md={8} lg={8} xl={8}>
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    placeholder="Filter by Rig Number"
                    onChange={(val) => handleChange(val, "rigNumbers")}
                    options={rigNumbers}
                  />
                </ColumnComponent>
              </Row>
            </Card.Grid>

            <Tooltip title={button.title} key={button.title}>
              <Card.Grid
                style={{
                  width: xs ? "20%" : "10%",
                  textAlign: "center",
                  padding: "10px 5px",
                  cursor: "pointer",
                  alignSelf: "center",
                  height: "100%",
                }}
                className={"card_grid"}
                hoverable
                onClick={button.action}
              >
                <span style={{ marginRight: "10px" }}>{button.icon}</span>
                {!xs && <span>{button.title}</span>}
              </Card.Grid>
            </Tooltip>
          </Card>
        </ColumnComponent>
        <ColumnComponent>
          <Card bordered hoverable className="card overflow_x">
            <Table
              columns={[...columns]}
              dataSource={[...data]}
              size={size}
              bordered
              loading={isLoading || isLoadinTable}
              rowKey={"OID"}
              onChange={onChange}
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Grand Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  {Object.keys(footer).map((key, index) => (
                    <Table.Summary.Cell key={key} index={index}>
                      {footer[key]}
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              )}
            />
          </Card>
        </ColumnComponent>
      </Row>
    </>
  );
};
